import '../styles/globals.css'
import {AppProps} from 'next/app'
import React from 'react'
import Head from 'next/head'
import Error from 'next/error'
import {registerSentry} from '../services/sentry'

registerSentry()

function MyApp({Component, pageProps, err}: AppProps & {err: any}) {
  if (pageProps.error) {
    return (
      <Error statusCode={pageProps.error.statusCode} title={pageProps.error.message} />
    )
  }

  return (
    <>
      <Head>
        <title>Reflect</title>
      </Head>
      <Component {...pageProps} err={err} />
    </>
  )
}

export default MyApp
